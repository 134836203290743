@import "../../styles/colors";
@import "../../styles/font";

.footerContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 87px;
  left: 0px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  box-shadow: 0 0 7px 0 $color-grey-shadow;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.footerContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home {
  background-image: url("../../assets/homeGrey.png");
  background-repeat: no-repeat;
  background-size: 34px 34px;
  height: 34px;
  padding-left: 45px;
  color: $color-grey;
  display: flex;
  align-items: flex-end;
  margin-right: 20px;
}

.homeActive {
  background-image: url("../../assets/homeOn.png");
  background-repeat: no-repeat;
  background-size: 34px 34px;
  height: 34px;
  padding-left: 45px;
  color: $color-orange;
  display: flex;
  align-items: flex-end;
  margin-right: 20px;
}

.updateHealth {
  background-image: url("../../assets/medGrey.png");
  background-repeat: no-repeat;
  background-size: 34px 34px;
  height: 34px;
  padding-left: 45px;
  color: $color-grey;
  display: flex;
  align-items: flex-end;
  margin-right: 20px;
}

.updateHealthActive {
  background-image: url("../../assets/medOrange.png");
  background-repeat: no-repeat;
  background-size: 34px 34px;
  height: 34px;
  padding-left: 45px;
  color: $color-orange;
  display: flex;
  align-items: flex-end;
  margin-right: 20px;
}

.updateHealthNotification {
  background-image: url("../../assets/medOn.png");
  background-repeat: no-repeat;
  background-size: 34px 34px;
  height: 34px;
  padding-left: 45px;
  color: $color-grey;
  display: flex;
  align-items: flex-end;
  margin-right: 20px;
}