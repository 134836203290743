@import '../../../styles/colors';
@import '../../../styles/font';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 100;
}

.modal {
    background-color: white;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 500px;
    max-height: 460px;
    background-color: $color-white;
    border-radius: 5px;
    // border: solid 1px $color-black;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;
  
    @media screen and (max-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 500px;
    max-height: 370px;
    background-color: $color-white;
    border-radius: 5px;
    // border: solid 1px $color-black;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10%;
    padding-right: 10%;
    
    @media screen and (max-width: 700px) {
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 10%;
        padding-right: 10%;
    }

    @media screen and (max-width: 363px) {
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 3%;
        padding-right: 3%;
    }


}

.headerText {
    margin-top: 30px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
    font-family: $font-gs;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.42;
    color: $color-blue-medium;
}


.headerText2 {
    font-family: $font-v;
    margin-bottom: 50px;
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
    color: $color-grey-brown;
}

.text{
    max-width: 100%;

    font-size: 24px;
    line-height: 1.17;
    font-weight: 300;
    color: $color-blue-medium;
    margin-bottom: 50px;

    @media screen and (max-width: 700px) {
        font-size: 20px;
    }
  
    @media screen and (max-height: 400px) {
        margin-bottom: 20px;
    }
   
    @media screen and (max-height: 350px) {
        margin-bottom: 10px;
    }
}

.block {
    display: block;
}

.none {
    display: none;
}

.cancelSignup {
    font-family: $font-gs;
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
    color: $color-grey-brown;
    text-decoration: underline;
    cursor: pointer;

    @media screen and (max-width: 700px) {
        font-size: 16px;
    }

    position: relative;

    &:after {
        content: ‘’;
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: -10px;
        right: -10px;    
    }
}

.finePrint {
    color: $color-grey-mid;
    margin-top: 20px;
    margin-bottom: 20px;
}


.cancel {
    text-decoration: underline;
    font-family: $font-gs;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    color: $color-grey-mid;
    position: relative;
    margin-top: 30px;
    cursor: pointer;
}

.textAfter {
    font-family: $font-v;
    font-size: 12px;
    text-align: center;
    color: $color-grey-mid;
    text-decoration: none; 
}

.facebook {
    width: 80px;
    height: 80px;
    margin-bottom: 19px;
    border-radius: 5px;
}

.facebookContainer {
    text-decoration: none; 
}
