@import '../../styles/colors';
@import '../../styles/font';

.textBox {
    width: 335px;
    height: 200px;
    border: solid 1px $color-grey-mid;
    padding: 10px;
}

.characterLimit {
    font-family: $font-v;
    font-size: 12px;
    line-height: 1.67;
    color: $color-grey-brown;
}

.container {
    margin-bottom: 50px;
}