// COLORS
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #999999;
$color-transparent: rgba(255, 255, 255, 0);
$color-orange: #ff6600;
$color-red-orange: #ff3300;
$color-blue-medium: #2373ae;
$color-blue-dark: #002a5c;
$color-blue-light: #49abd7;
$color-grey-brown: #555555;
$color-grey-light: #ebebeb;
$color-grey-background: #fafafa;
$color-green-blue: #63f8ba;
$color-green: #417505;
$color-grey-mid: #555555;
$color-grey-alt: #9b9b9b;
$color-grey-border: #979797;
$color-grey-slider: #b0b0b0;
$color-grey-text: #4a4a4a;

$color-greenish-blue: rgba(56, 205, 139, 0.25);
$color-bright-orange: rgba(255, 102, 0, 0.33);
$color-blue-modal: rgba(35, 115, 174, 0.95);
$color-red-inputs: rgba(255, 51, 0, 0.05);
$color-green-inputs: rgba(65, 117, 5, 0.05);

$color-bronze: #845b02;
$color-gold: #dead04;

$color-orange-dark: #ff7730;

$color-black: #000;

$color-green-notification: #38cd8b;

$color-google-grey: #757575;
$color-facebook-blue: #1877F2;

$color-grey-shadow: #DDDDDD;