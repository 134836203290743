@import '../../../styles/colors';
@import '../../../styles/font';
@import '../../styles/margins';

.container {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;

    padding-bottom: $padding-bottom-large;
    height: 100%;

    @media screen and (max-width: 700px) {
        padding-bottom: $padding-bottom-medium;
    }

    @media screen and (max-width: 363px) {
        padding-bottom: $padding-bottom-small;
    }
}

.input {
    width: 100%;
    max-width: 400px;
    height: 44px;
    border-radius: 5px;
    border: solid 1px $color-grey-mid;
    margin-bottom: 20px;
    padding-left: 9px;
}

%base-input {
    font-family: $font-v;
    font-size: 16px;
    padding-left: 9px;
    line-height: 2.75;
    color: $color-grey-brown;
    padding-left: 9px;
}

input[type="text"] {
    @extend %base-input;
}

.form {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    margin-top: 0px;
    margin-left: 40px;

    @media screen and (max-width: 730px) {
        flex-direction: column;
        margin-right: 20px;
        margin-top: 0px;
        margin-left: 20px;
    }
}

.errorText {
    font-family: $font-v;
    font-size: 14px;
    line-height: 1.71;
    color: $color-red-orange;
    margin-bottom: 10px;
    margin-left: 10px;

    width: 350px;

    @media screen and (max-width: 1023px) {
        width: 300px;
    }
}

.label {
    font-family: $font-v;
    font-size: 14px;
    margin-bottom: 5px;
    color: $color-grey-brown;
  }

.errorInput {
    border: solid 1px $color-red-orange;
}