@import "../../../styles/colors";
@import "../../../styles/font";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
}

.modal {
  background-color: white;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 400px;
  max-height: 500px;
  background-color: $color-white;
  border-radius: 5px;
  border: solid 1px $color-black;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: auto;
}

.block {
  display: block;
}

.none {
  display: none;
}

.headerText {
  font-family: $font-gs;
  font-size: 26px;
  font-weight: 300;
  color: $color-blue-medium;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 30px;

  @media screen and (max-width: 600px) {
    font-size: 26px;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 350px) {
    margin-left: 10px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  width: 76px;
  height: 44px;
  border-radius: 5px;
  border: solid 1px $color-grey-mid;
  margin-bottom: 10px;
  padding-left: 9px;
}

%base-input {
  font-family: $font-v;
  font-size: 16px;
  padding-left: 9px;
  line-height: 2.75;
  color: $color-grey-brown;
}

input[type="text"] {
  @extend %base-input;
}

input[type="number"] {
  @extend %base-input;
}

input[type="tel"] {
  @extend %base-input;
}

select {
  @extend %base-input;
}

select.errorInput {
  border: solid 1px $color-red-orange;
}

.cancel {
  text-decoration: underline;
  font-family: $font-gs;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: $color-grey-mid;
  position: relative;
  cursor: pointer;
  margin-top: 35px;

  &:after {
    content: ‘’;
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
  }
}

.bottomButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 20px;
}

.errorInput {
  border: solid 1px $color-red-orange;
  padding-left: 9px;
}

.errorText {
  font-family: $font-v;
  font-size: 14px;
  line-height: 1.71;
  color: $color-red-orange;
  margin-bottom: 4px;
  margin-left: 10px;
  width: 300px;
}

.errorText2 {
  font-family: $font-v;
  font-size: 14px;
  line-height: 1.71;
  color: $color-red-orange;
  margin-bottom: 20px;
  margin-left: 10px;

  width: 350px;

  @media screen and (max-width: 1023px) {
    width: 300px;
  }
}

.headerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.weightLogo {
  width: 50px;
  margin-left: 50px;
  margin-top: 25px;

  @media screen and (max-width: 350px) {
    margin-left: 30px;
  }
}

.inputHeaderText {
  font-family: $font-v;
  font-size: 14px;
  line-height: 3.14;
  color: $color-grey-brown;
}

.inputModifier {
  font-family: $font-gs;
  font-size: 26px;
  font-weight: 600;
  color: $color-grey-brown;
  margin-left: 8px;
  margin-bottom: 4px;
}

.inputTextSize {
  font-family: $font-gs;
  font-size: 36px;
  font-weight: 600;
  color: $color-grey-brown;
}

.weightContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.topFlex {
  display: flex;
}

.inputContainer {
  margin-right: 35px;
  margin-left: 35px;

  @media screen and (max-width: 400px) {
    margin-right: 15px;
    margin-left: 15px;
  }
}
