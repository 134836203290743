@import "../../styles/colors";
@import "../../styles/font";

%baseHeader {
  font-family: $font-gs;
  font-size: 36px;
  font-weight: 300;
  color: $color-blue-medium;
  margin-left: 40px;
  margin-bottom: 66px;
  margin-top: 68px;
  line-height: 1.25;

  @media screen and (max-width: 1023px) {
    margin-left: 40px;
    margin-bottom: 58px;
    margin-top: 58px;
    font-size: 32px;
  }

  @media screen and (max-width: 700px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
    margin-top: 40px;
    font-size: 24px;
  }
}

.mainHeader {
  @extend %baseHeader;
}

.mainHeaderAltSub {
  @extend %baseHeader;
  margin-left: 40px;
  margin-bottom: 8px;
  margin-top: 68px;

  @media screen and (max-width: 1023px) {
    margin-bottom: 6px;
    margin-top: 58px;
    font-size: 32px;
  }

  @media screen and (max-width: 700px) {
    margin-left: 20px;
    margin-top: 40px;
    font-size: 24px;
  }
}

.mainHeaderAltModal {
  @extend %baseHeader;
  margin-left: 0px;
  margin-top: 60px;
  margin-bottom: 26px;
}

.mainHeaderPreviewWorkout {
  @extend %baseHeader;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 50px !important;
}

.centered {
  @extend %baseHeader;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 26px;
}

.mainHeaderPricing {
  @extend %baseHeader;
  margin-left: 0px;
  margin-top: 60px;
  margin-bottom: 26px;

  @media screen and (min-width: 950px) {
    margin-top: 52px;
  }
}

.mainHeaderPricingSmallerMargin {
  font-family: $font-gs;
  font-size: 36px;
  font-weight: 300;
  color: $color-blue-medium;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 1.25;
  width: 600px;

  @media screen and (max-width: 1023px) {
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 32px;
  }

  @media screen and (max-width: 700px) {
    // margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 24px;
  }
  
  @media screen and (max-width: 490px) {
    // margin-left: 20px;
    width: 300px;
  }
}

.mainHeaderPricingSmallerMargin2 {
  @extend %baseHeader;
  margin-left: 0px;

  margin-top: 60px;
  margin-bottom: 26px;

  @media screen and (min-width: 950px) {
    margin-top: 52px;
  }
}

.subHeader {
  font-family: $font-v;
  font-size: 20px;
  line-height: 1.3;
  color: $color-grey-mid;

  margin-left: 40px;
  margin-bottom: 66px;
  margin-top: -70px;
  margin-right: 40px;

  @media screen and (max-width: 1023px) {
    margin-left: 40px;
    margin-bottom: 40px;
    margin-top: -58px;
    margin-right: 40px;
    font-size: 16px;
    line-height: 1.38;
  }

  @media screen and (max-width: 700px) {
    margin-left: 20px;
    margin-bottom: 40px;
    margin-right: 20px;
    margin-top: -42px;
    font-size: 14px;
    line-height: 1.43;
  }
}

.centeredSub {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: -20px;
}


.subHeaderDark {
  color: $color-grey-brown;
  margin-top: -64px;
  margin-bottom: 0px;

  @media screen and (max-width: 1023px) {
    margin-top: -52px;
  }

  @media screen and (max-width: 700px) {
    margin-top: -36px;
  }
}

.subHeaderBold {
  padding-top: 5px;
  font-weight: bold;
}

.subHeaderBold2 {
  padding-top: 5px;
  font-weight: bold;
  margin-bottom: 10px;
}

.citation {
  font-family: $font-v;
  font-size: 10px;
  font-style: italic;
  color: $color-grey-mid;
  max-width: 300px;

  margin-left: 40px;
  margin-bottom: 66px;
  margin-top: -60px;
  margin-right: 40px;

  @media screen and (max-width: 1023px) {
    margin-left: 40px;
    margin-bottom: 40px;
    margin-top: -48px;
    margin-right: 40px;
    line-height: 1.38;
  }

  @media screen and (max-width: 700px) {
    margin-left: 20px;
    margin-bottom: 40px;
    margin-right: 20px;
    margin-top: -32px;
    line-height: 1.43;
  }
}

.dashboard {
  font-size: 24px;
  line-height: 1.17;

  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  width: 60%;
}

.dashboard2 {
  font-size: 24px;
  line-height: 1.17;

  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.boldDarkMargin {
  color: $color-grey-mid;
  margin-bottom: 40px;

  @media screen and (max-width: 700px) {
    margin-bottom: 20px;
  }
}

.mainHeaderSmallBottomPadding {
  padding-bottom: 30px;
}

.mainHeaderNoBottomPadding {
  padding-bottom: 0px;
}

.smallerMargin {
  margin-bottom: 20px;
  margin-left: 0px;
  line-height: 1.25;
}

.orangeSubHead {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: $color-orange;
  margin-top: -20px;
  margin-bottom: 46px;

  @media screen and (max-width: 1023px) {
    margin-bottom: 36px;
  }

  @media screen and (max-width: 700px) {
    font-size: 16px;
    margin-top: -20px;
    margin-bottom: 22px;
  }
}

.paymentHeader {
  font-family: $font-gs;
  font-size: 36px;
  font-weight: 300;
  color: $color-orange;
  margin-bottom: 10px;

  @media screen and (max-width: 1023px) {
    font-size: 32px;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 700px) {
    font-size: 24px;
    margin-bottom: 6px;
  }
}

.subHeaderNoMargin {
  margin-top: 10px;
  margin-bottom: 20px;

  @media screen and (max-width: 1023px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 700px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.subHeaderProfileMargin {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;

  @media screen and (max-width: 1023px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 700px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.orangeHeader {
  color: $color-orange;
}

.smallMarginBottom {
  margin-bottom: 10px;
}

.smallMarginBottomWidth {
  margin-bottom: 10px;
  max-width: 450px;
}

.assessmentHeader {
  @extend %baseHeader;
  margin-bottom: 20px;

  @media screen and (max-width: 1023px) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 700px) {
    margin-bottom: 10px;
    margin-left: 40px;
  }

  @media screen and (max-width: 500px) {
    margin-left: 20px;
  }
}