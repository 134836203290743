@import '../../../styles/colors';
@import '../../../styles/font';
@import '../../styles/margins';

.container {
    height: 100%;
    background: $color-blue-medium;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: $padding-bottom-large;

    @media screen and (max-width: 760px) {
        background: $color-white;
    }

    @media screen and (max-width: 700px) {
        padding-bottom: $padding-bottom-medium;
    }

    @media screen and (max-width: 363px) {
        padding-bottom: $padding-bottom-small;
    }
}

.main {
    margin: auto;
    margin-top: 80px;
    max-width: 900px;
    max-height: 100%;
    background-color: $color-white;
    border-radius: 15px;
    border: solid 1px $color-black;

    display: flex;
    flex-direction: column;

    padding-bottom: 68px;
    min-width: 450px;

    @media screen and (max-width: 1023px) {
        padding-bottom: 58px;
    }

    @media screen and (max-width: 760px) {
        border: 0px;
        margin-top: 0px;
    }

    @media screen and (max-width: 700px) {
        padding-bottom: 40px;
    }
}

.contentContainer {
    font-family: $font-v;
    font-size: 20px;
    font-weight: 300;
    margin-left: 40px;
    margin-right: 40px;
    max-width: 650px;
    margin-top: -40px;

    @media screen and (max-width: 1023px) {
        font-size: 18px;
    }

    @media screen and (max-width: 700px) {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 16px;
        max-width: 400px;
    }
}

.fieldText {
    font-family: $font-v;
    font-size: 20px;
    line-height: 1.5;
    color: $color-grey-brown;
    margin-bottom: 12px;

    margin-top: 60px;

    @media screen and (max-width: 1023px) {
        margin-top: 50px;
    }
}

.genderField {
    margin-bottom: 70px;
}

.birthDate {
    width: 62px;
    height: 44px;
    border-radius: 5px;
    border: solid 1px $color-grey-mid;
}

.birthDateYear {
    width: 72px;
    height: 44px;
    border-radius: 5px;
    border: solid 1px $color-grey-mid;
}

.birthFields {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.slash {
    height: 1px;
    width: 40px;
    background: $color-grey-brown;
    margin: -5px;
    transform: rotate(-65deg);
}

.specificInputBirth {
    // margin-top: 60px;

    // @media screen and (max-width: 1023px) {
    //     margin-top: 40px;
    // }

    // @media screen and (max-width: 700px) {
    //     margin-top: 20px;
    // }
}

.errorText {
    font-family: $font-v;
    font-size: 14px;
    line-height: 1.71;
    color: $color-red-orange;
    margin-bottom: 10px;
}

.inputWeight {
    width: 60px;
    height: 44px;
    border-radius: 5px;
    border: solid 1px $color-grey-mid;
}

.inputDescriptionWeight {
    font-family: $font-gs;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}

.weight {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inputHeight {
    width: 60px;
    height: 44px;
    border-radius: 5px;
    border: solid 1px $color-grey-mid;
    margin-left: 10px;
    margin-right: 20px;
}

.height {
    display: flex;
    align-items: center;
    margin-bottom: 34px;
}

.inputDescriptionHeight {
    font-family: $font-gs;
    font-size: 20px;
    font-weight: 500;
    color: $color-grey-brown;
}

.errorInput {
    border: solid 1px $color-red-orange;
}

.errorDisabledButton {
    flex-direction: column;
}

.errorText2 {
    font-family: $font-v;
    font-size: 14px;
    line-height: 1.71;
    color: $color-red-orange;
    margin-bottom: 20px;
    margin-left: 10px;

    width: 350px;

    @media screen and (max-width: 1023px) {
        width: 300px;
    }
}
