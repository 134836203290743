@import "../../styles/colors";
@import "../../styles/font";

%base-text {
  font-family: $font-gs;
  font-size: 20px;
  font-weight: 500;
  color: $color-grey-brown;

  @media screen and (max-width: 1023px) {
    font-size: 18px;
  }

  @media screen and (max-width: 700px) {
    font-size: 16px;
  }
}

.checkboxContainer {
  @extend %base-text;
  display: flex;
  align-items: center;
  height: 34px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 60px;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 34px;
  width: 34px;
  background-color: $color-white;
  border-radius: 3px;
  border: solid 1px $color-grey-mid;
  background-color: $color-white;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
  @media (hover: hover) and (pointer: fine) {
    background-color: $color-grey-mid;
  }
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: $color-white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  //   left: 14px;
  position: absolute;
  width: 30px;
  height: 30px;
  //   border: solid $color-grey-mid;
  //   border-width: 0 3px 3px 0;
  // -webkit-transform: rotate(45deg);
  // -ms-transform: rotate(45deg);
  // transform: rotate(45deg);
  background-image: url("../../assets/Vector1.svg");
  background-repeat:no-repeat;
  background-size: cover;
  background-position: center; 
}

.Terms {
  margin-bottom: 44px;

  @media screen and (max-width: 1023px) {
    margin-bottom: 36px;
  }

  @media screen and (max-width: 700px) {
    margin-bottom: 29px;
    margin-left: 50px;
    margin-right: 25px;
  }

  @media screen and (max-width: 363px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.assessments {
  max-width: 400px;
  min-height: 44px;
  display: inline-block;
  position: relative;
}

.disabled {
  max-width: 400px;
  min-height: 44px;
  display: inline-block;
  position: relative;
  color: $color-grey-mid;
}

.assessmentsNone {
  max-width: 400px;
  min-height: 44px;
  display: inline-block;
  position: relative;
}

.disabledNone {
  max-width: 400px;
  min-height: 44px;
  display: inline-block;
  position: relative;
  color: $color-grey-mid;
}
