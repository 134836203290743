@import '../../styles/colors';
@import '../../styles/font';

.button {
  background-color: $color-orange-dark;
  color: $color-white;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 38px;
  padding-right: 38px;
  border-radius: 3px;
  border: solid 2px $color-red-orange;
  font-family: $font-gs;
  text-align: center;
  position: relative;
  font-size: 26px;
  font-weight: 600;

  @media screen and (max-width: 700px) {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 11px;
    padding-top: 9px;
    font-size: 20px;
  }

  // increases the touch size of the button
  // so that you can click slightly outside the button
  // &:after {
  //     content: '';
  //     position: absolute;
  //     top: -5px;
  //     bottom: -5px;
  //     left: -5px;
  //     right: -5px;
  // }
}

.triangle {
  padding-right: 50px;

  @media screen and (max-width: 700px) {
    padding-right: 40px;
  }
}

.buttonSmaller {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.buttonSmallerLeftRight {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;

  @media screen and (max-width: 350px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.buttonSmallerSub {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;

  @media screen and (max-width: 363px) {
    font-size: 16px;
  }
}

.buttonSmallerTopAndBottom {
  padding-top: 9px;
  padding-bottom: 9px;
}

.buttonAlternate {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  color: $color-orange;
  border: solid 2px $color-orange;
  background-color: $color-white;
  font-size: 26px;
  font-weight: 600;
  white-space: nowrap;
}

.buttonAlternateSmall {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 30px;
  padding-right: 30px;
  color: $color-orange;
  border: solid 2px $color-orange;
  background-color: $color-white;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.Disabled {
  border: solid 2px $color-grey-mid;
  background-color: $color-grey;
  color: $color-grey-mid;
  cursor: not-allowed !important;
}

.arrowRight {
  position: absolute;
  top: -5px;
  right: -7px;
  background-color: white;
  text-align: left;
  margin: 1.2em;
}

.arrowRight:before,
.arrowRight:after {
  content: '';
  position: absolute;
  background-color: inherit;
}

.arrowRight,
.arrowRight:before,
.arrowRight:after {
  width: 8px;
  height: 8px;
  border-top-right-radius: 30%;
}


.arrowRight {
  transform: rotate(-90deg) skewX(-30deg) scale(1, .866);
}

.arrowRight:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, .707) translate(0, -50%);
}

.arrowRight:after {
  transform: rotate(135deg) skewY(-45deg) scale(.707, 1.414) translate(50%);
}

.moreMargin {
  margin-top: 30px;
}