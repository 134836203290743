@import "../../../styles/colors";
@import "../../../styles/font";
@import "../../../styles/margins";

.container {
  height: 100%;
  min-height: 100vh;
  background: $color-blue-medium;
  padding-bottom: $padding-bottom-large;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 760px) {
    background: $color-white;
  }

  @media screen and (max-width: 700px) {
    padding-bottom: $padding-bottom-medium;
  }

  @media screen and (max-width: 363px) {
    padding-bottom: $padding-bottom-small;
  }
}

.main {
  margin: auto;
  margin-top: 80px;
  max-width: 900px;
  max-height: 100%;
  background-color: $color-white;
  border-radius: 15px;
  border: solid 1px $color-black;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 760px) {
    border: 0px;
    margin-top: 0px;
  }
}

.mainContainer {
  @media screen and (min-width: 1025px) {
    max-width: 1024px;
    margin-right: auto;
    margin-left: auto;
  }

  display: flex;
  flex-direction: column;
  width: 100%;
}

.subheader {
  font-family: $font-v;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 300;
  color: $color-grey-mid;

  margin-left: 40px;
  margin-bottom: 68px;
  margin-right: 15px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 58px;
    line-height: 1.38;
    font-size: 16px;
  }

  @media screen and (max-width: 760px) {
    margin-left: 20px;
    margin-bottom: 52px;
    font-size: 14px;
    line-height: 1.43;
  }
}

.question {
  font-family: $font-v;
  font-size: 20px;
  color: $color-grey-brown;
  min-height: 70px;
  margin-bottom: 20px;
  max-width: 550px;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 700px) {
    min-height: 50px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 500px) {
    padding-left: 20px;
  }

  @media screen and (max-width: 340px) {
    margin-bottom: 15px;
    padding-right: 20px;
    max-width: 100%;
  }
}

.citation {
  font-family: $font-v;
  font-size: 12px;
  color: $color-grey-brown;
  margin-top: 50px;
  padding-left: 40px;
  padding-right: 40px;
  @media screen and (max-width: 700px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.questionLeadIn {
  font-family: $font-v;
  font-size: 22px;
  font-weight: 600px;
  line-height: 1.5;
  text-align: center;
  color: $color-grey-brown;
  margin-bottom: 40px;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 570px) {
    font-size: 20px;
    max-width: 100%;
    margin-left: 20px;
    // margin-right: 20px;
  }
}

.checkBoxes {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
}

.questionContainer {
  max-width: 650px;
  padding-bottom: 68px;

  @media screen and (max-width: 1023px) {
    padding-bottom: 58px;
  }

  @media screen and (max-width: 700px) {
    padding-bottom: 40px;
  }
}

.errorText {
  font-family: $font-v;
  font-size: 14px;
  line-height: 1.71;
  color: $color-red-orange;
  margin-bottom: 20px;
  margin-left: 10px;
}

.backgroundStyleChecked {
  background-color: #fafafa;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 20px;

  @media screen and (max-width: 500px) {
    padding-left: 20px;
  }
}

.backgroundStyleNotChecked {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 20px;

  @media screen and (max-width: 500px) {
    padding-left: 20px;
  }

  @media screen and (max-width: 363px) {
    padding-right: 10px;
  }
}

.radioButtonPadding {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (max-width: 363px) {
    padding-left: 10px;
  }
}

.paddingLeft {
  padding-left: 40px;
  padding-right: 40px;
  @media screen and (max-width: 700px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.followUpImage {
  border: solid 1px $color-grey-light;
  margin-bottom: 22px;
  max-width: 450px;
  width: 100%;
  height: 100%;
  margin-top: 15px;
}

.followUpText {
  margin-bottom: 37px;
  max-width: 450px;

  font-family: $font-v;
  font-size: 18px;
  line-height: 1.44;
  color: $color-grey-text;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.fadeInContainer {

  min-height: 220px;

  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}


.range {
  width: 50px;
  height: 44px;
  border-radius: 5px;
  border: solid 1px $color-grey-mid;
  margin-bottom: 16px;
  padding-left: 9px;

  @media screen and (max-width: 370px) {
      margin-left: 15px;
      margin-right: 15px;
      width: 100%
  }
}


.input {
  width: 76px;
  height: 44px;
  border-radius: 5px;
  border: solid 1px $color-grey-mid;
  margin-bottom: 10px;
  padding-left: 9px;
}
