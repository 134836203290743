@import "../../../styles/colors";
@import "../../../styles/font";
@import "../../../styles/margins";

.checkInContainer {
  height: 100%;
  margin-bottom: 150px;

  @media screen and (min-width: 1025px) {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }

  padding-bottom: $padding-bottom-large-2;

  @media screen and (max-width: 700px) {
    padding-bottom: $padding-bottom-medium-2;
  }

  @media screen and (max-width: 363px) {
    padding-bottom: $padding-bottom-small-2;
  }
}

.container {
  margin-left: 40px;
  position: relative;
  // margin-bottom: 100px;

  @media screen and (max-width: 760px) {
    margin-left: 10px;
    // margin-bottom: 60px;
  }
}

.questionText {
font-size: 14px;
line-height: 140%;
color: $color-grey-brown;
font: $font-v;
margin-top: 5px;
margin-bottom: 28px;
}

.marginSides {
  margin: 20px
}

.bottomButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  width: 455px;

  @media screen and (max-width: 440px) {
   width: 100%;
}
}

.cancel {
  text-decoration: underline;
  font-family: $font-gs;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: $color-grey-mid;
  margin-top: 40px;
  position: relative;
  cursor: pointer;

  &:after {
      content: ‘’;
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
  }
}
