@import '../../../styles/colors';
@import '../../../styles/font';
@import '../../../styles/margins';

.container {
    height: 100%;
    max-width: 1024px;

    @media screen and (min-width: 1025px) {
        margin-left: 200px;
    }

    padding-bottom: $padding-bottom-large;

    @media screen and (max-width: 700px) {
        padding-bottom: $padding-bottom-medium;
        margin-left: 0px;
    }

    @media screen and (max-width: 700px) {
        padding-bottom: $padding-bottom-small;
    }
}

.radioButtonsContainer {
    text-align: center;
    margin-top: 20px;
}

.thumbUp {
    margin: 20px;
    height: 89px;
}

.thumbDown {
    margin: 20px;
    height: 89px;
}

.preText {
    font-family: $font-v;
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.43;
    color: $color-grey-brown;
    max-width: 350px;
}

.cancel {
    text-decoration: underline;
    font-family: $font-gs;
    font-size: 16px;
    font-weight: 600;
    color: $color-grey-mid;
    position: relative;
    text-align: center;
    cursor: pointer;
    margin-top: 30px;
}

.mainContainer {
    margin-left: 40px;
    max-width: 400px;

    @media screen and (max-width: 1023px) {
        margin-left: 40px;
    }

    @media screen and (max-width: 700px) {
        margin-left: 20px;
    }
}

.buttonsContainer {
    max-width: 355px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
