@import '../../styles/colors';

.rectangle {
    box-shadow: 0 0 7px 0 $color-grey-shadow;
    border: solid 1px $color-grey-light;
    background-color: $color-white;
}

.orange {
    box-shadow: 0 0 7px 0 $color-bright-orange;
    height: 80px;
    width: 455px;
    display: flex;

    @media screen and (max-width: 1023px) {
        height: 60px;
        max-width: 46%;
    }

    @media screen and (max-width: 782px) {
        width: 330px;
        margin-left: 22px;
    }

    @media screen and (max-width: 758px) {
        max-width: 100%;
        width: 455px;
        margin-bottom: 20px;
        margin-right: 20px;
        margin-left: 30px;
    }

    @media screen and (max-width: 700px) {
        max-width: 455px;
        margin-left: 22px;
        margin-right: 22px;
        position: relative;
        position: relative;
        width: -moz-calc(100% - 22px);
        width: -webkit-calc(100% - 22px);
        width: -o-calc(100% - 22px);
        width: calc(100% - 22px);
    }

}

.previewWorkout {
    width: 455px;
    height: 230px;
    margin-bottom: 30px;
    margin-right: 37px;

    @media screen and (max-width: 1023px) {
        height: 230px;
        width: 455px;
        margin-bottom: 30px;
        margin-right: 30px;
    }

    @media screen and (max-width: 782px) {
        width: 455px;
    }

    @media screen and (max-width: 758px) {
        max-width: 100%;
        width: 455px;
        height: 220px;
        margin-bottom: 20px;
        margin-right: 0px;
    }

    @media screen and (max-width: 440px) {
        max-width: 100%;
        width: 455px;
        height: 220px;
        margin-bottom: 20px;
        margin-right: 0px;
    }

}

.dashboard {
    width: 455px;
    height: 250px;
    margin-bottom: 30px;
    margin-right: 37px;

    @media screen and (max-width: 1023px) {
        max-width: 46%;
        height: 200px;
        margin-bottom: 30px;
        margin-right: 30px;
    }

    @media screen and (max-width: 782px) {
        width: 330px;
    }

    @media screen and (max-width: 758px) {
        max-width: 100%;
        width: 455px;
        height: 200px;
        margin-bottom: 20px;
        margin-right: 0px;
    }

}

.account {
    width: 455px;
    height: 200px;
    margin-bottom: 30px;
    margin-right: 35px;
    position: relative;

    @media screen and (max-width: 1023px) {
        margin-right: 0px;
        width: 90%;
        margin-bottom: 30px;
    }

    @media screen and (max-width: 950px) {
        width: 97%;
        max-width: 425px;
    }

    @media screen and (max-width: 900px) {
        width: 97%;
        max-width: 400px;
    }

    @media screen and (max-width: 880px) {
        width: 97%;
        max-width: 370px;
    }

    @media screen and (max-width: 760px) {
        max-width: 455px;
        height: 200px;
        margin-bottom: 20px;

        width: -moz-calc(100% - 20px);
        width: -webkit-calc(100% - 20px);
        width: -o-calc(100% - 20px);
        width: calc(100% - 20px);
    }

    @media screen and (max-width: 500px) {
        width: 97%;
    }

}

.member {
    min-height: 210px;

    @media screen and (max-width: 1023px) {}

    @media screen and (max-width: 760px) {}

    @media screen and (max-width: 400px) {
        min-height: 230px;
    }
}

.assessment {
    height: 100%;
    padding-bottom: 50px;
    min-height: 300px;

    @media screen and (max-width: 1023px) {}

    @media screen and (max-width: 760px) {}
}

.payment {
    min-height: 130px;

    @media screen and (max-width: 1023px) {}

    @media screen and (max-width: 760px) {}
}

.login {
    min-height: 160px;

    @media screen and (max-width: 1023px) {
        margin-bottom: 30px;
    }

    @media screen and (max-width: 760px) {
        margin-bottom: 20px;
    }
}

.contact {
    min-height: 270px;

    @media screen and (max-width: 1023px) {
        margin-bottom: 30px;
    }

    @media screen and (max-width: 760px) {
        margin-bottom: 20px;
    }
}


.profile {
    width: 455px;
    min-height: 270px;
    margin-right: 35px;
    position: relative;
    margin-bottom: 30px;

    @media screen and (max-width: 1023px) {
        max-width: 46%;
        margin-right: 30px;
    }

    @media screen and (max-width: 782px) {
        width: 355px;
    }

    @media screen and (max-width: 758px) {
        max-width: 100%;
        width: 455px;
        min-height: 230px;
        margin-bottom: 20px;
        margin-right: 0px;
    }

}


.modalDash {
    width: 455px;
    height: 240px;
    margin-bottom: 74px;
    padding-top: 40px;

    @media screen and (max-width: 1023px) {
        width: 330px;
    }

    @media screen and (max-width: 758px) {
        max-width: 100%;
        padding-top: 30px;
        margin-left: 7px;
        margin-right: 7px;
    }

}


.modalDashError {
    width: 455px;
    height: 275px;
    margin-bottom: 74px;
    padding-top: 40px;

    @media screen and (max-width: 1023px) {
        width: 330px;
    }

    @media screen and (max-width: 758px) {
        max-width: 100%;
        padding-top: 30px;
        margin-left: 7px;
        margin-right: 7px;
    }

}

.share {
    width: 320px;
    min-height: 280px;
    margin-right: 35px;
    position: relative;
    margin-bottom: 30px;

    @media screen and (max-width: 758px) {
        max-width: 100%;
        margin-right: 10px;
    }
}

.coachBox {
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 10px;
        height: 100%;
        max-height: 300px;
        max-width: 1000px;
      
        @media screen and (max-width: 1023px) {
          margin-left: 40px;
        }
      
        @media screen and (max-width: 700px) {
          margin-left: 0px;
          margin-right: 0px;
        }
      
        @media screen and (max-width: 350px) {
        }
      
}