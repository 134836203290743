@import "../../../styles/colors";
@import "../../../styles/font";
@import "../../../styles/margins";

.dashboard {
  height: 100%;
  max-width: 500px;
  margin: auto;
  margin-bottom: 150px;

  @media screen and (min-width: 1025px) {
    margin-right: auto;
    margin-left: auto;
  }

  padding-bottom: $padding-bottom-large;

  @media screen and (max-width: 700px) {
    padding-bottom: $padding-bottom-medium;
  }

  @media screen and (max-width: 700px) {
    padding-bottom: $padding-bottom-small;
  }

  @media screen and (max-width: 550px) {
    padding-bottom: $padding-bottom-small;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.top {
}

.body {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.marginLeft {
  margin-bottom: 60px;
  margin-top: 10px;
  width: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.topHeader {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 760px) {
    margin-bottom: -10px;
  }
}

.listHeader {
  font-family: $font-gs;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.25;
  color: $color-blue-medium;
  margin-bottom: 20px;

  @media screen and (max-width: 1023px) {
    font-size: 24px;
  }

  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
}

.bullet {
  font-family: $font-v;
  font-size: 16px;
  line-height: 1.38;
  color: $color-grey-brown;
  margin-bottom: 18px;
  margin-right: 20px;
}

ul {
  list-style-position: outside;
  list-style-type: none;
}

li {
  position: relative;
}

li:before {
  content: "\2022";
  color: $color-grey-mid;
  border-radius: 50%;
  font-size: 24px;
  position: absolute;
  left: -20px;
  top: -6px;
}

.cardTitle {
  font-family: $font-v;
  font-size: 14px;
  text-align: center;
  color: $color-grey-brown;
  margin-bottom: 13px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 11px;
  margin-left: 11px;
  height: 500px;

  @media screen and (max-width: 440px) {
    margin-right: 5px;
    margin-left: 5px;
  }

  @media screen and (max-width: 370px) {
    height: 180px;
  }

  @media screen and (max-width: 350px) {
    &:not(:last-child) {
      margin-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 5px;
    }
  }
}

.cards {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  height: 130px;

  @media screen and (max-width: 370px) {
    margin-bottom: 30px;
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
  }
}

.box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.boxContainer {
  width: 200px;
  height: 150px;
  box-shadow: 0 0 7px 0 $color-grey-shadow;
  border: solid 1px $color-grey-shadow;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    width: 180px;
  }
  
  @media screen and (max-width: 460px) {
    width: 170px;
  }

  @media screen and (max-width: 370px) {
    width: 200px;
  }

}

.box2 {
  width: 200px;
  height: 150px;
  box-shadow: 0 0 9px 0 $color-grey-shadow;
  border: solid 1px $color-grey-shadow;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  cursor: pointer !important;
  
  @media screen and (max-width: 480px) {
    width: 180px;
  }
  
  @media screen and (max-width: 460px) {
    width: 170px;
  }

  @media screen and (max-width: 370px) {
    width: 200px;
  }
  
}

.box3 {
  width: 200px;
  height: 380px;
  box-shadow: 0 0 9px 0 $color-grey-shadow;
  border: solid 1px $color-grey-shadow;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  @media screen and (max-width: 480px) {
    width: 180px;
  }
  
  @media screen and (max-width: 460px) {
    width: 170px;
  }

  @media screen and (max-width: 370px) {
    width: 200px;
  }
}

.boxParkinsons {
  width: 320px;
  height: 100px;
  box-shadow: 0 0 9px 0 $color-grey-shadow;
  border: solid 1px $color-grey-shadow;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  padding-top: 26px;
}

.boxParkinsonsOpen {
  width: 320px;
  height: 360px;
  box-shadow: 0 0 9px 0 $color-grey-shadow;
  border: solid 1px $color-grey-shadow;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  padding-top: 26px;
  z-index: 100;
}

.durationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.innerBoxHighlighted {
  width: 75px;
  height: 100px;
  box-shadow: 2px 0 2px 0 $color-grey-shadow;
  background-color: $color-white;

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  cursor: pointer !important;
}

.innerBoxHighlightedRight {
  width: 75px;
  height: 100px;
  box-shadow: -2px 0 2px 0 $color-grey-shadow;
  background-color: $color-white;

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer !important
}

.innerBoxGrey {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 75px;
  height: 100px;
  cursor: pointer !important
}

.workoutIcon {
  max-height: 35px;
  max-width: '100%';
  margin-bottom: 13px;
}

.workoutText {
  font-family: $font-v;
  font-size: 12px;
  text-align: center;
  color: $color-grey-brown;
}

.workoutTextDisabled {
  font-family: $font-v;
  font-size: 12px;
  text-align: center;
  color: $color-grey-mid;
}

.durationIcon {
  width: 47px;
  height: 47px;
}

.minutesContainer {
  display: flex;
  flex-direction: column;
  margin-left: 13px;
}

.minutesNumber {
  font-family: $font-v;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.29;
  color: $color-blue-medium;
  display: flex;
  align-items: center;
}

.minutesText {
  font-family: $font-v;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: $color-blue-medium;
}

.minutesTriangle {
  margin-left: 5px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 12px solid $color-blue-medium;
}

.minutesTriangleBig {
  margin-left: 5px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 12px solid $color-blue-medium;
  position: relative;
  right: -160px;
  top: 5px;
}

.minutesTriangleUp {
  margin-left: 5px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid $color-blue-medium;
}

.minutesTriangleBigUp {
  margin-left: 5px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid $color-blue-medium;
  position: relative;
  right: -160px;
  top: 5px;
}

.dropdown {
  margin-top: 28px;
  padding-left: 19px;
  z-index: 100;
  background-color: $color-white;
  border-radius: 7px;
  padding-bottom: 10px;
}

.dropdownItemText {
  font-size: 24px;
  font-weight: bold;
}

.minutesDropdownText {
  font-family: $font-v;
  font-size: 16px;
  color: $color-grey-brown;
}

.dropdownItem {
  padding-left: 20px;
}

.dropdownItem:not(:last-child) {
  width: 127px;
  padding-bottom: 10px;
  border-bottom: solid 1px $color-grey-light;
}

.bottomCardContainer {
  display: flex;
}

.basicLogo {
  width: 54px;
}

.bottomCard {
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: pointer !important;
}

.bottomCardText {
  margin-top: 15px;
  font-family: $font-v;
  font-size: 14px;
  text-align: center;
  color: $color-grey-brown;
}

.bottomCardCard {
  width: 150px;
  height: 100px;
  border-radius: 7px;
  box-shadow: 0 0 7px 0 $color-grey-shadow;
  border: solid 1px $color-greenish-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.notifyText {
  font-family: $font-v;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.notificationContainer {
  width: 100%;
  height: 62px;

  @media screen and (max-width: 600px) {
    height: 50px;
  }

  position: fixed;
  z-index: 100;
  top: 0px;
  border: solid 1px $color-green-notification;
  background-color: $color-green-notification;
}

.noWorkoutText {
  font-family: $font-v;
  font-size: 16px;
  line-height: 1.38;
  color: $color-grey-brown;
  max-width: 400px;
  margin-bottom: 39px;
}

.noWorkoutContainer {
  margin-top: 50px;
}

.cancel {
  font-family: $font-gs;
  font-size: 20px;
  color: $color-grey-brown;
  text-decoration: underline;
  margin-top: 60px;
}

.center {
  text-align: center;
}

.greyTextUnderlined {
  font-family: $font-gs;
  font-size: 20px;
  font-weight: 500;
  margin-top: 30px;
  color: $color-grey-mid;
  text-decoration: underline;
  cursor: pointer !important;
}

.workoutPreviewText {
  margin-top: 50px;
  margin-bottom: 24px;
  font-family: $font-v;
  font-size: 16px;
  line-height: 1.38;
  color:$color-grey-brown;
}

.workoutPreviewTextBold {
  font-weight: bold;
}

.workoutPreviewMainText {
  font-family: $font-v;
  font-size: 16px;
  line-height: 1.38;
  color:$color-grey-brown;
  font-weight: bold;
  margin-bottom: 24px;
}

.workoutPreviewMainTextLessMargin {
  font-family: $font-v;
  font-size: 16px;
  line-height: 1.38;
  color:$color-grey-brown;
  font-weight: bold;
  margin-bottom: 16px;
}

.panelContainer {
  margin-top: 15px;
  margin-left: 15px;
}

.bullet {
  font-family: $font-v;
  font-size: 16px;
  line-height: 1.63;
  color: $color-grey-brown;
  margin-bottom: 5px;
  position: relative;
  padding-left: 15px;
  
  @media screen and (max-width: 420px) {
    font-size: 14px;
  }

}

.bullet:before {
  content: "• ";
  font-size: 20px;
  // width: 10px;
  // height: 10px;
  color: $color-grey-mid;
  padding-right: 5px;

  position: absolute;
  left: 0;
  top: -0.2em;

  @media screen and (max-width: 760px) {
      width: 8px;
      height: 8px;
  }

  @media screen and (max-width: 440px) {
    font-size: 18px;
  }
  
  @media screen and (max-width: 420px) {
    font-size: 16px;
  }
}

.preview {
}

.timeText {
  margin-top: 24px;
  margin-bottom: 38px;
  font-family: $font-v;
  font-size: 16px;
  line-height: 1.38;
  color:$color-grey-brown;
  font-weight: bold;
}

.timeTextOrange {
  color:$color-orange;
}

.listOfWhat {
margin-bottom: 40px;
}

.workoutIcon2 {
  width: 47px;
  height: 100%;
  margin-right: 21px;
}

.iconFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.redicon{
  position: absolute;
  top: 15px;
  right: 25px;
  background: $color-red-orange;
  padding:7px;
  z-index: 100;
  box-sizing: border-box;
  border-radius: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.previewWorkout {
  width: 50%;
  height: auto;
  margin-top: 20px;
  cursor: pointer;
}

.coachHeader {
  font-size: 24px;
  font-family: $font-v;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;

  @media screen and (max-width: 760px) {
    font-size: 20px;
}
  @media screen and (max-width: 470px) {
    font-size: 16px;
}
}

.coachText {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: $font-v;
  margin-left: 30px;
  margin-right: 30px;

  @media screen and (max-width: 760px) {
    font-size: 18px;
}

@media screen and (max-width: 470px) {
    font-size: 16px;
}
}
