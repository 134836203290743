@import '../../../styles/colors';
@import '../../../styles/font';
@import '../../styles/margins';

.container {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    margin-right: 40px;
    margin-top: 48px;
    margin-left: 40px;
    padding-bottom: $padding-bottom-large;
    height: 100%;

    @media screen and (max-width: 700px) {
        padding-bottom: $padding-bottom-medium;
        flex-direction: column;
        margin-right: 20px;
        margin-top: 29px;
        margin-left: 20px;
    }

    @media screen and (min-width: 1025px) {
        padding-bottom: $padding-bottom-small;
        flex-direction: column;
        margin-right: auto;
        margin-left: auto;
        padding-right: 40px;
        padding-left: 40px;
    }
}

.topRow {
    margin-bottom: 50px;

    @media screen and (max-width: 700px) {
        margin-bottom: 40px;
    }

    @media screen and (max-width: 363px) {
        // margin-bottom: 80px;
    }
}

.bottomRow {
    display: flex;

    @media screen and (max-width: 830px) {
        flex-direction: column-reverse;
    }
    
}

.leftColumn {
    flex: 1;
    margin-right: 140px;
    max-width: 400px;

    @media screen and (max-width: 1023px) {
        margin-right: 87px;
    }

    @media screen and (max-width: 830px) {
        margin-right: auto;
        margin-left: auto;
        max-width: 350px;
    }
  
    &MainContent {
        max-width: 380px;

        @media screen and (max-width: 1023px) {
            max-width: 300px;
        }

        @media screen and (max-width: 700px) {
            max-width: 100%;
        }
    }
}

.leftColumnMainContent {
    max-width: 300px;
}

.mainHeader {
    color: $color-blue-medium;
    font-family: $font-gs;
    max-width: 100%;
    font-size: 36px;
    font-weight: 300;

    @media screen and (max-width: 720px) {
        font-size: 30px;
        max-width: 100%;
    }

    @media screen and (max-width: 700px) {
        font-size: 26px;
        max-width: 100%;
    }
}

.subHeader {
    font-family: $font-gs;
    font-size: 24px;
    font-weight: 300;
    color: $color-grey-brown;
    max-width: 600px;
    margin-top: 30px;

    @media screen and (max-width: 1023px) {
        font-size: 20px;
    }

    @media screen and (max-width: 730px) {
        font-size: 18px;
        max-width: 450px;
    }

    @media screen and (max-width: 700px) {
        font-size: 18px;
    }


}

.signUpHeader {
    font-family: $font-gs;
    font-size: 20px;
    font-weight: 300;
    color: $color-grey-brown;
    margin-bottom: 10px;

    @media screen and (max-width: 830px) {
        margin-bottom: 30px;
    }
}


.basicInputStyle {
    width: 350px;
    height: 41px;
    font-family: $font-v;
    font-size: 16px;
    line-height: 2.75;
    // color: $color-grey-mid;

    @media screen and (max-width: 700px) {
        width: 100%;
    }
}

.passwordRequirements {
    font-family: $font-v;
    font-size: 12px;
    color: $color-black;
    margin-bottom: 7px;
    margin-top: 16px;

    @media screen and (max-width: 1023px) {
        font-size: 10px;
    }
}

.input {
    width: 350px;
    height: 44px;
    border-radius: 5px;
    border: solid 1px $color-grey-mid;
    margin-bottom: 16px;

    font-family: $font-v;
    font-size: 16px;
    padding-left: 9px;
    line-height: 2.75;
    // color: $color-grey-mid;

    @media screen and (max-width: 1023px) {
        width: 300px;
        margin-right: 10px;
    }

    @media screen and (max-width: 500px) {
        margin-right: 10px;
    }

    @media screen and (max-width: 363px) {
        width: 100%;
        margin-right: 10px;
    }
}

.alreadyAMember {
    font-family: $font-gs;
    font-size: 18px;
    font-weight: 500;
    color: $color-grey-brown;
    margin-top: 49px;
}

.blueSpan {
    cursor: pointer;
    color: $color-blue-medium;
    text-decoration: underline;
}


.errorInput {
    border: solid 1px $color-red-orange;
    background-color: $color-red-inputs !important;
}

.validatedInput {
    border: solid 1px $color-green;
    background-color: $color-green-inputs;
}

.errorText {
    font-family: $font-v;
    font-size: 14px;
    line-height: 1.71;
    color: $color-red-orange;
    margin-bottom: 4px;
    margin-left: 10px;
}

.passwordContainer {
    display: flex;
    flex-direction: column;

    width: 350px;

    @media screen and (max-width: 1023px) {
        width: 300px;
        margin-right: 10px;
    }

    @media screen and (max-width: 500px) {
        margin-right: 10px;
    }

    @media screen and (max-width: 363px) {
        width: 100%;
        margin-right: 10px;
    }
}

input:focus {
    /* removing the input focus blue box. Put this on the form if you like. */
    outline: none;
}

.inputWrapper {
    position: relative;
}

.iconStyle {
    margin-left: -35px;
    color: $color-green;
}

.appleContinue {
    width: 100%;
    height: 100%;
    max-height: 52px;
    max-width: 359px;
}

.facebookContinue {
    width: 100%;
    height: 100%;
    max-height: 52px;
    max-width: 359px;
    margin-top: 10px;
}

.googleContinue {
    width: 100%;
    object-fit:contain;
    max-width: 365px;
    margin-top: 10px;
}

.horizontal {
    width: 2px;
    height: 150px;
    border: solid 1px #979797;

    @media screen and (max-width: 850px) {
        margin-left: -50px;
        margin-right: 0px;
    }

    @media screen and (max-width: 830px) {
       margin-left: 0px;
       margin-right: 0px;
       width: 150px;
       height: 2px;
    }
}

.or {
    font-family: $font-v;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: $color-grey-mid;
    margin-top: 5px;
    margin-bottom: 5px;

    @media screen and (max-width: 830px) {
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.flexColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -30px;
    margin-right: 20px;

    @media screen and (max-width: 870px) {
        margin-left: -40px;
        margin-right: 10px;
    }
    @media screen and (max-width: 850px) {
        margin-left: -50px;
        margin-right: 0px;
    }
    @media screen and (max-width: 850px) {
        flex-direction: row;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.ownInfo {
    font-family: $font-gs;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    text-align: center;
    color: $color-grey-brown;
    margin-top: 20px;
    margin-bottom: 20px;
}

.rightColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
    
    @media screen and (min-width: 830px) {
        min-width: 400px;
    }
    @media screen and (max-width: 830px) {
        padding-left: 0px;
    }
}
  .logoContinue {
    height: 22px;
    width: 22px;
    position: absolute;
    left: 16px;   
  }
  
  .loginButtonApple {
    height: 50px;
    background-color: $color-black;
    font-size: 24px;
    border-radius: 8px;
    width: 100%;
    object-fit:contain;
    max-width: 365px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: $color-white;
    margin-bottom: 12px;
    font-weight: 500;
  
    @media screen and (max-width: 430px) {
      font-size: 20px;
      font-weight: 400;
    }
  }
  
  .loginButtonGoogle {
    height: 50px;
    background-color: $color-white;
    font-size: 24px;
    color: $color-google-grey;
    border-radius: 8px;
    width: 100%;
    object-fit:contain;
    max-width: 365px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;  
    border-color: $color-google-grey;
    border-width: 1px;
    border-style: solid;
    font-weight: 500;
  
    @media screen and (max-width: 430px) {
      font-size: 20px;
      font-weight: 400;
    }
    }
  
  .loginButtonFacebook {
    height: 50px;
    background-color: $color-facebook-blue;
    color: $color-white;
    font-size: 24px;
    border-radius: 8px;
    width: 100%;
    object-fit:contain;
    max-width: 365px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 12px;
    font-weight: 500;
  
    @media screen and (max-width: 430px) {
      font-size: 20px;
      font-weight: 400;
    }
  }