@import "../../../styles/colors";
@import "../../../styles/font";
@import "../../../styles/margins";

.container {
  height: 100%;
  max-width: 330px;
  margin: auto;
  margin-bottom: 400px;

  @media screen and (min-width: 1025px) {
    margin-right: auto;
    margin-left: auto;
  }

  padding-bottom: $padding-bottom-large;

  @media screen and (max-width: 700px) {
    padding-bottom: $padding-bottom-medium;
  }

  @media screen and (max-width: 700px) {
    padding-bottom: $padding-bottom-small;
  }

  @media screen and (max-width: 400px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 350px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.top {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 56px;
}

.bottomCardContainer {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
}

.basicLogo {
  width: 54px;
}

.bottomCard {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

.bottomCardText {
  margin-top: 15px;
  font-family: $font-v;
  font-size: 14px;
  text-align: center;
  color: $color-grey-brown;
  margin-right: 20px;
  margin-bottom: 25px;
}

.bottomCardCard {
  width: 150px;
  height: 100px;
  box-shadow: 0 0 7px 0 $color-grey-shadow;
  border: solid 1px $color-greenish-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  position: relative;
  
  @media screen and (max-width: 350px) {
    width: 140px;
    height: 100px;
  }
}

.headerText {
  font-family: $font-gs;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.17;
  color: $color-blue-medium;
  max-width: 240px;
  margin-right: 20px;

  @media screen and (max-width: 400px) {
    font-size: 20px;
    max-width: 220px;
  }
}

.notifyText {
  font-family: $font-v;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.notificationContainer {
  width: 100%;
  height: 62px;

  @media screen and (max-width: 600px) {
    height: 50px;
  }
  position: fixed;
  z-index: 100;
  top: 0px;
  border: solid 1px $color-green-notification;
  background-color: $color-green-notification;
}

.redicon{
  position: absolute;
  top: 15px;
  right: 30px;
  background: $color-red-orange;
  padding:7px;
  z-index: 100;
  box-sizing: border-box;
  border-radius: 100%;
}

.redicon2{
  position: absolute;
  top: 20px;
  right: 40px;
  background: #ff3300;
  padding: 7px;
  z-index: 100;
  box-sizing: border-box;
  border-radius: 100%;
}
