@import '../../styles/colors';
@import '../../styles/font';

.container {
    display: flex;
    justify-content: center;
}

.vertical {
    flex-direction: column;
    width: 250px;
}

.verticalPadding {
    flex-direction: column;
    width: 250px;
    padding-left: 17px;
    padding-bottom: 30px;
    padding-top: 15px;
}

.verticalPaddingAssessment {
    flex-direction: column;
    width: 250px;
    padding-bottom: 30px;
    padding-top: 15px;

    @media screen and (max-width: 363px) {
        padding-right: 20px;
    }
}

.verticalFlexStart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 280px;
}

.alignLeft {
    justify-content: flex-start;
    margin-bottom: 40px;

    margin-left: 40px;

    @media screen and (max-width: 600px) {
        margin-left: 20px;
    }

}

.locationModal {
    margin-bottom: 20px;
    margin-left: 5px;

    @media screen and (max-width: 370px) {
        margin-left: 40px;
    }
}

.location {
    margin-bottom: 20px;
    margin-left: 5px;
    justify-content: flex-start;
}


.radioButton {
    opacity: 0;
    position: absolute;
}

.radioButton,
.radioLabel {
    vertical-align: middle;
    cursor: pointer;

    display: -moz-inline-box;
    display: inline-block;
    vertical-align: middle;
}

.radioLabel {
    position: relative;
    font-size: 20px;
    font-family: $font-gs;
    margin-right: 36px;
    margin-left: 0px;
    color: $color-grey-brown;

    display: -moz-inline-box;
    display: inline-block;
    vertical-align: middle;
}

.orangeText {
    color: $color-orange;
}

.radioButton+.radioLabel:before {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    content: '';

    margin-top: auto;
    margin-bottom: auto;

    background: white;
    border: 1px solid $color-grey-brown;
    width: 34px;
    height: 34px;
    padding: 2px;
    margin-right: 13px;
    text-align: center;
    border-radius: 50%;

    display: -moz-inline-box;
}


.radioButton+.errorInput:before {
    border: solid 1px $color-red-orange;
}

.radioButton:checked+.radioLabel:before {
    background: $color-grey-brown;
    box-shadow: inset 0px 0px 0px 4px #fff;
}

@media(hover: hover) and (pointer: fine) {
    .radioButton:hover+.radioLabel:before {
        background: $color-grey-brown;
        box-shadow: inset 0px 0px 0px 4px #fff;
    }
}

.verticalPrices {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    margin: 0 0 30px 0;
}

.verticalPrices:last-child {
    margin: 0;
}

.labelText {
    width: 100%;
    margin-left: 60px;

    @media screen and (max-width: 370px) {
        margin-left: 55px;
    }
}


.radioItem:not(:last-child) {
    margin-bottom: 20px;
}

.radioItemNoMargin:not(:last-child) {
    margin-bottom: 0px;
}

.Discount0 {
    text-decoration: line-through;
}

.Discount1 {
    color: red;
}

.divider {
    width: 298px;
    height: 1px;
    border: solid 1px $color-grey-light;
    margin-bottom: 15px;

    @media screen and (max-width: 790px) {
        width: 250px;
    }
}

.selectionText{
    font-family: $font-gs;
    font-size: 20px;
    font-weight: 300;
    line-height: 0.89;
    color: $color-grey-brown;
    margin-bottom: 15px;
}