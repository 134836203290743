@import '../../../styles/colors';
@import '../../../styles/font';
@import '../../../styles/margins';

.container {
    height: 100%;
    max-width: 500px;

    @media screen and (min-width: 1025px) {
        margin-left: auto;
	margin-right: auto;
    }

    padding-bottom: $padding-bottom-large;

    @media screen and (max-width: 700px) {
        padding-bottom: $padding-bottom-medium;
    }

    @media screen and (max-width: 363px) {
        padding-bottom: $padding-bottom-small;
    }
}

.radioButtonsContainer {
    text-align: center;
    margin-top: 20px;
}

.thumbUp {
    margin: 20px;
    height: 89px;
}

.thumbDown {
    margin: 20px;
    height: 89px;
}

.postWorkoutText {
    font-family: $font-v;
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
    color: $color-grey-brown;
    margin-left: 20px;
    margin-right: 20px;
}

.radioButtonsContainer {
    :hover {
        opacity: .8;
        cursor: pointer;
    }
}
