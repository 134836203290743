@import '../../../styles/colors';
@import '../../../styles/font';
@import '../../../styles/margins';

.background {
    width: 100%;
    background: $color-blue-medium;
    padding-top: 130px;
    min-height: 100vh;
    padding-left: 10px;
    padding-right: 10px;

    display: flex;

    @media screen and (min-width: 1025px) {
        margin-left: auto;
        margin-right: auto;
    }

    padding-bottom: $padding-bottom-large;
    height: 100%;

    @media screen and (max-width: 700px) {
        padding-bottom: $padding-bottom-medium;
        padding-top: 90px;
    }

    @media screen and (max-width: 363px) {
        padding-bottom: $padding-bottom-small;
        padding-top: 30px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.main {
    margin: auto;
    max-width: 600px;
    max-height: 640px;
    background-color: $color-white;
    border-radius: 5px;
    border: solid 1px $color-black;

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 700px) {
        max-height: 640px;
    }
}

.headerContainer {
    display: flex;
    justify-content: center;
}

.text {
    font-family: $font-v;
    font-size: 20px;
    line-height: 1.3;
    color: $color-grey-brown;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 26px;

    @media screen and (max-width: 700px) {
        margin-left: 20px;
        margin-right: 20px;
    }

}

.content {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;

    @media screen and (max-width: 700px) {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.input {
    width: 525px;
    height: 60px;
    border-radius: 5px;
    border: solid 1px $color-grey-mid;
    margin-bottom: 20px;

    font-family: $font-v;
    font-size: 16px;
    padding-left: 9px;
    line-height: 2.75;
    // color: $color-grey-mid;

    @media screen and (max-width: 700px) {
        max-width: 525px;
        width: 100%;
        max-height: 640px;
    }

}

.bottomButtons {
    margin-top: 20px;
    margin-bottom: 82px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.errorInput {
    border: solid 1px $color-red-orange;
}

.errorText {
    font-family: $font-v;
    font-size: 14px;
    line-height: 1.71;
    color: $color-red-orange;
    margin-bottom: 4px;
    margin-left: 10px;
    width: 300px;
}


.blueText {
    margin-top: 70px;
    font-size: 36px;
    font-weight: 300;
    text-align: center;
    font-family: $font-gs;
    font-size: 36px;
    font-weight: 300;
    color: $color-blue-medium;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 140px;

    @media screen and (max-width: 700px) {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 24px;
        margin-bottom: 100px;
        margin-top: 50px;
    }
}

.cancel {
    text-decoration: underline;
    font-family: $font-gs;
    font-size: 16px;
    font-weight: 600;
    color: $color-grey-mid;
    position: relative;
    margin-bottom: 160px;
    text-align: center;
    cursor: pointer;

    @media screen and (max-width: 700px) {
        margin-bottom: 100px;
    }
}