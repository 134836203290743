// $padding-bottom-small: 370px;
// $padding-bottom-medium: 350px;
// $padding-bottom-large: 260px;

// $padding-bottom-small-2: 340px;
// $padding-bottom-medium-2: 320px;
// $padding-bottom-large-2: 230px;

$padding-bottom-small: 20px;
$padding-bottom-medium: 20px;
$padding-bottom-large: 20px;

$padding-bottom-small-2: 20px;
$padding-bottom-medium-2: 20px;
$padding-bottom-large-2: 20px;
