@import "../../../styles/colors";
@import "../../../styles/font";
@import "../../../styles/margins";

.manageAccout {
  height: 100%;
  margin-bottom: 150px;

  @media screen and (min-width: 1025px) {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }

  padding-bottom: $padding-bottom-large-2;

  @media screen and (max-width: 700px) {
    padding-bottom: $padding-bottom-medium-2;
  }

  @media screen and (max-width: 363px) {
    padding-bottom: $padding-bottom-small-2;
  }
}

.container {
  margin-left: 40px;
  position: relative;
  // margin-bottom: 100px;

  @media screen and (max-width: 760px) {
    margin-left: 10px;
    // margin-bottom: 60px;
  }
}

.panelContainer {
  margin-left: 20px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1023px) {
    margin-left: 15px;
    margin-top: 11px;
  }

  @media screen and (max-width: 760px) {
    margin-left: 13px;
    margin-right: 10px;
  }
}

.panelHeader {
  font-family: $font-gs;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.17;
  color: $color-blue-medium;
  margin-bottom: 20px;

  @media screen and (max-width: 1023px) {
    margin-bottom: 16px;
    font-size: 24px;
  }

  @media screen and (max-width: 760px) {
    margin-bottom: 16px;
    font-size: 20px;
  }
}

.panelHeader2 {
  font-family: $font-gs;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.17;
  color: $color-blue-medium;
  margin-bottom: 20px;

  @media screen and (max-width: 1023px) {
    margin-bottom: 16px;
    font-size: 24px;
  }

  @media screen and (max-width: 760px) {
    margin-bottom: 16px;
    font-size: 20px;
  }

  @media screen and (max-width: 400px) {
    margin-bottom: 45px;
    font-size: 20px;
  }
}

.panelField {
  display: flex;

  @media screen and (max-width: 1023px) {
    margin-left: 7px;
    margin-right: 7px;
  }

  @media screen and (max-width: 760px) {
    margin-left: 7px;
    margin-right: 7px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
}

.line {
  width: 95%;
  height: 2px;
  border: solid 1px $color-grey-light;
  margin-top: 15px;
  margin-bottom: 15px;
}

%base-text {
  font-family: $font-gs;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.38;
  color: $color-grey-brown;
}

.panelText {
  @extend %base-text;
  min-width: 220px;
  font-weight: bold;
  line-height: 1.57;

  @media screen and (max-width: 420px) {
    width: 110px;
  }
}

.panelText2 {
  @extend %base-text;
  min-width: 110px;
  font-weight: bold;
  line-height: 1.57;
}

.panelText3 {
  @extend %base-text;
  min-width: 170px;
  font-weight: bold;
  line-height: 1.57;
}

.panelResponseMember {
  @extend %base-text;
}

.panelResponseMember2 {
  @extend %base-text;
  margin-bottom: 0px;
  margin-top: auto;
}

.panelResponseLogin {
  @extend %base-text;
}

// same for all screen sizes
.edit {
  position: absolute;
  bottom: 20px;
  font-family: $font-gs;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  color: $color-blue-medium;
  text-decoration: underline;
  cursor: pointer;
  bottom: 15px;
  right: 15px;
}

.basicLogo {
  position: absolute;
  top: 16px;
  right: 18px;
  width: 50px;
}

.preButtonText {
  font-size: 14px;
  color: $color-grey-brown;
  margin-left: 15px;
}

.buttonContainer {
  text-align: center;
  margin-top: 40px;
}

.notifyText {
  font-family: $font-v;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.notificationContainer {
  width: 100%;
  height: 62px;

  @media screen and (max-width: 600px) {
    height: 50px;
  }
  position: fixed;
  z-index: 100;
  top: 0px;
  border: solid 1px $color-green-notification;
  background-color: $color-green-notification;
}
.notifyText {
  font-family: $font-v;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.notificationContainer {
  width: 100%;
  height: 62px;

  @media screen and (max-width: 600px) {
    height: 50px;
  }
  position: fixed;
  z-index: 100;
  top: 0px;
  border: solid 1px $color-green-notification;
  background-color: $color-green-notification;
}

.logoContinue {
  height: 22px;
  width: 22px;
  position: absolute;
  left: 16px;   
}

.loginButtonApple {
  height: 50px;
  background-color: $color-black;
  font-size: 24px;
  border-radius: 8px;
  width: 100%;
  object-fit:contain;
  max-width: 365px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: $color-white;
  margin-bottom: 12px;
  font-weight: 500;

  @media screen and (max-width: 430px) {
    font-size: 20px;
    font-weight: 400;
  }
}

.loginButtonGoogle {
  height: 50px;
  background-color: $color-white;
  font-size: 24px;
  color: $color-google-grey;
  border-radius: 8px;
  width: 100%;
  object-fit:contain;
  max-width: 365px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;  
  border-color: $color-google-grey;
  border-width: 1px;
  border-style: solid;
  font-weight: 500;

  @media screen and (max-width: 430px) {
    font-size: 20px;
    font-weight: 400;
  }
  }

.loginButtonFacebook {
  height: 50px;
  background-color: $color-facebook-blue;
  color: $color-white;
  font-size: 24px;
  border-radius: 8px;
  width: 100%;
  object-fit:contain;
  max-width: 365px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 12px;
  font-weight: 500;

  @media screen and (max-width: 430px) {
    font-size: 20px;
    font-weight: 400;
  }
}
