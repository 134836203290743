@import '../../../styles/colors';
@import '../../styles/font';
@import '../../styles/margins';


.container {
    @media screen and (min-width: 1025px) {
        width: 1024px;
        margin-left: auto;
        margin-right: auto;
    }

    padding-bottom: $padding-bottom-large;
    height: 100%;

    @media screen and (max-width: 700px) {
        padding-bottom: $padding-bottom-medium;
    }

    @media screen and (max-width: 363px) {
        padding-bottom: $padding-bottom-small;
        margin-bottom: -170px;
    }
}

b {
    font-weight: bold;
}

.termsContainer {
    width: 800px;
    height: 600px;
    border: solid 1px #979797;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1023px) {
        width: 700px;
    }

    @media screen and (max-width: 700px) {
        width: 100%;
        border: none;
        height: 100%;
        background-color: white;
        margin-bottom: -180px;
        padding-bottom: 100px;
    }
}

.termsBody {
    margin-left: 24px;
    margin-right: 36px;
    margin-bottom: 32px;

    @media screen and (max-width: 363px) {
        margin-left: 20px;
        margin-right: 12px;
    }
}

.confirm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 36px;

    @media screen and (max-width: 700px) {
        position: fixed;
        z-index: 10;
        background-color: white;
        bottom: 0;
        left: 0;

        border-top: solid 1px #979797;

        padding-top: 56px;
        width: 100%;
        padding-bottom: 91px;
    }

    @media screen and (max-width: 363px) {
        padding-top: 40px;
        padding-bottom: 30px;
    }
}