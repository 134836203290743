@import "../../styles/colors";
@import "../../styles/font";

.border {
  border-bottom: 1px solid $color-orange;
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  justify-content: space-between;
  height: 62px;
  width: 100vw;

  @media screen and (max-width: 600px) {
    height: 50px;
  }

  @media screen and (min-width: 1023px) {
    max-width: 1024px;
    width: 1024px;
  }
}

.leftContainer {
  height: 100%;
  position: relative;
}

.basicLogo {
  height: 80%;
  margin-top: 5px;
  margin-left: 2vw;
  position: absolute;
  cursor: pointer;
}

.rightContainer {
  display: flex;
  position: relative;
}

.nonMobile {
  display: flex;

  @media screen and (max-width: 600px) {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    position: absolute;
    right: 0;
    bottom: 0.1px;
  }
}

.mobile {
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 600px) {
    display: none;
  }
}

.statusButton {
  font-family: $font-gs;
  font-size: 20px;
  line-height: 3.1;
  font-weight: 300;
  text-align: right;
  color: $color-grey;
  padding-left: 20px;
  background-color: $color-white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:last-child {
    padding-right: 20px;
  }
}

.statusButtonBlue {
  font-family: $font-gs;
  font-size: 20px;
  line-height: 3.1;
  font-weight: 300;
  text-align: right;
  color: $color-white;
  padding-right: 20px;
  padding-left: 20px;
  background-color: $color-blue-modal;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.triangleUp {
  -webkit-text-stroke: 1px $color-white;
  color: transparent;
  margin-left: 7px;
  margin-top: 7px;
  font-size: 15px;
}

.triangleDown {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  /* IE 9 */
  -moz-transform: rotate(180deg);
  /* Firefox */
  -webkit-transform: rotate(180deg);
  /* Safari and Chrome */
  -o-transform: rotate(180deg);
  -webkit-text-stroke: 1px $color-blue-medium;
  color: transparent;
  margin-left: 7px;
  font-size: 15px;
  margin-top: -3px;
}

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  background-color: $color-blue-modal;
  z-index: 200;
}

.modalHamburger {
  position: absolute;
  right: 12px;
  top: 12px;
}

.modalLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
}

.modalText {
  width: 230px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: $font-gs;
  font-size: 30px;
  font-weight: 300;
  color: $color-white;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: thin solid $color-white;
  }
}

.accountDropdown {
  @media screen and (max-width: 600px) {
    display: none;
  }

  width: 250px;
  height: 240px;
  background-color: $color-blue-modal;
  position: fixed;
  right: 0;
  top: 60px;
  z-index: 10;

  @media screen and (min-width: 1025px) {
    position: relative;
    height: 240px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 30px;
}

.accountDropdownItem {
  color: white;
  font-size: 24px;
  font-weight: 300;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 200px;
  text-align: end;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: thin solid $color-white;
  }
}
