@import "fonts/fontello/css/fontello.css";

.App {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  min-height:100vh;
  position:relative;
}

*:focus {
  outline: none;
}

body {
  margin:0;
  padding:0;
  height:100%;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}