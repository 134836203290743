@import '../../styles/colors';
@import '../../styles/font';

.hamburger {
    width: 100%;
    height: 34px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.line {
  width: 20px;
  height: 4px;
  border-radius: 6px;
  background-color: $color-grey-alt;
}

.white {
  background-color: $color-white;
}

.hamburgerMenu {
  display: flex;
  align-items: center;
}

.text {
  font-size: 16px;
  color: $color-grey-brown;
  margin-left: 5px;
}

.whiteText {
  color: $color-white;
}

.gearLogo {
  width: 34px;
  height: 34px;

  @media screen and (max-width: 420px) {
    width: 28px;
    height: 28px;
  }
}