@charset "UTF-8";
@font-face {
  font-family: 'fontello';
  src: url("../font/fontello.eot?66141683");
  src: url("../font/fontello.eot?66141683#iefix") format("embedded-opentype"), url("../font/fontello.woff2?66141683") format("woff2"), url("../font/fontello.woff?66141683") format("woff"), url("../font/fontello.ttf?66141683") format("truetype"), url("../font/fontello.svg?66141683#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?66141683#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-eye:before {
  content: '\e800'; }

/* '' */
.icon-eye-off:before {
  content: '\e801'; }

/* '' */
.icon-ok:before {
  content: '\e802'; }

/* '' */
.icon-twitter:before {
  content: '\f099'; }

/* '' */
.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-pinterest-circled:before {
  content: '\f0d2'; }

/* '' */
.icon-youtube:before {
  content: '\f167'; }

/* '' */
.icon-instagram:before {
  content: '\f16d'; }

/* '' */
.icon-linkedin-squared:before {
  content: '\f30c'; }

/* '' */
