@import '../../../styles/colors';
@import '../../../styles/font';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    margin-bottom: 87px;
}

.modal {
    background-color: white;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 500px;
    max-height: 750px;
    background-color: $color-white;
    border-radius: 5px;
    border: solid 1px $color-black;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;

    @media screen and (max-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
    }

    @media screen and (max-width: 460px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 600px;
    }
}


.headerContainer {
    display: flex;
    justify-content: center;
}

.text {
    font-family: $font-v;
    font-size: 20px;
    line-height: 1.3;
    color: $color-grey-brown;
    // margin-left: 40px;
    // margin-right: 40px;
    margin-bottom: 26px;

    @media screen and (max-width: 700px) {
        // margin-left: 20px;
        // margin-right: 20px;
    }

    @media screen and (max-width: 420px) {
        margin-left: 0px;
        margin-right: 0px;
    }

    @media screen and (max-width: 350px) {
        font-size: 14px;
    }

}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;

    @media screen and (max-width: 700px) {
        margin-left: 20px;
        margin-right: 20px;
    }

    @media screen and (max-width: 420px) {
        margin-left: 5px;
        margin-right: 5px;
    }

    @media screen and (max-width: 350px) {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.input {
    max-width: 525px;
    height: 60px;
    border-radius: 5px;
    border: solid 1px $color-grey-mid;
    margin-bottom: 20px;

    font-family: $font-v;
    font-size: 16px;
    padding-left: 9px;
    line-height: 2.75;
    // color: $color-grey-mid;

    @media screen and (max-width: 700px) {
        max-width: 525px;
        width: 100%;
        max-height: 640px;
    }

}

.bottomButtons {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 455px) {
        align-items: normal;
        flex-direction: column;
        justify-content: space-between;
        height: 140px;
    }
    
}

.errorInput {
    border: solid 1px $color-red-orange;
}

.errorText {
    font-family: $font-v;
    font-size: 14px;
    line-height: 1.71;
    color: $color-red-orange;
    margin-bottom: 4px;
    margin-left: 10px;
    width: 300px;
}

.cancel {
    text-decoration: underline;
    font-family: $font-gs;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    color: $color-grey-mid;
    position: relative;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
}


.finePrint {
    color: $color-grey-mid;
    margin-top: 20px;
    margin-bottom: 10px;
}
