@import "../../../styles/colors";
@import "../../../styles/font";
@import "../../../styles/margins";

.background {
  background-image: url("../../../assets/LoginBGblue.jpg");
  background-size: cover;
  background-position: center;
  background-position: 0% 0%;
  width: 100%;
  height: 100%;
  display: flex;
}

.main {
  margin-top: 80px;
  width: 600px;
  max-height: 780px;
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 700px) {
    max-height: 760px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
  }
  
  @media screen and (max-width: 415px) {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 370px) {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
 
  @media screen and (max-width: 350px) {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
}

.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

img.loginLogo {
  width: 240px;
  border-bottom: 1px solid $color-white;
}

.loginText {
  font-family: GillSans;
  font-size: 24px;
  color: $color-white;
  margin-top: 10px;
  margin-bottom: 20px;
}

.headerText {
  font-family: GillSans;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.17;
  color: $color-white;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 22px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  width: 320px;
  height: 60px;
  border-radius: 5px;
  border: solid 1px $color-grey-mid;
  margin-bottom: 10px;

  font-family: $font-v;
  font-size: 16px;
  padding-left: 9px;
  line-height: 2.75;

  @media screen and (max-width: 700px) {
    width: 300px;
    max-height: 640px;
  }
}

.loginHelp {
  text-decoration: underline;
  font-family: $font-gs;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: $color-white;
  cursor: pointer;
}

.bottomButtons {
  margin-top: 20px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  width: 100%;
}

.additionalText {
  font-family: $font-v;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: $color-white;
  margin-bottom: 6px;
}

.inlineText {
  font-weight: 500;
  color: $color-white;
  text-decoration: underline;
}

.errorInput {
  border: solid 1px $color-red-orange;
}

.errorText {
  font-family: $font-v;
  font-size: 14px;
  line-height: 1.71;
  color: $color-red-orange;
  margin-bottom: 4px;
  margin-left: 10px;
  width: 300px;
}

.iconStyle {
  font-size: 20px;
  color: $color-grey-brown;
  border: 1px solid $color-white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -50px;
  margin-top: 10px;
}

.passwordInputContainer {
  display: flex;
  width: 320px;

  @media screen and (max-width: 700px) {
    width: 300px;
  }
}

.horizontal {
  border: solid 1px $color-white;
     width: 150px;
     height: 2px;

     @media screen and (max-width: 370px) {
      width: 100px;
    }
}

.or {
  font-family: $font-v;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: $color-white;
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 0px;
      margin-bottom: 0px;
}

.flexRow {
  display: flex;
  justify-content: center;
  align-items: center;
      flex-direction: row;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 20px;
      margin-bottom: 40px;
}

.logoContinue {
  height: 22px;
  width: 22px;
  position: absolute;
  left: 16px;   
}

.loginButtonApple {
  height: 50px;
  background-color: $color-black;
  font-size: 24px;
  border-radius: 8px;
  width: 100%;
  object-fit:contain;
  max-width: 365px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: $color-white;
  margin-bottom: 12px;
  font-weight: 500;

  @media screen and (max-width: 430px) {
    font-size: 20px;
    font-weight: 400;
  }
}

.loginButtonGoogle {
  height: 50px;
  background-color: $color-white;
  font-size: 24px;
  color: $color-google-grey;
  border-radius: 8px;
  width: 100%;
  object-fit:contain;
  max-width: 365px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;  
  border-color: $color-google-grey;
  border-width: 1px;
  border-style: solid;
  font-weight: 500;

  @media screen and (max-width: 430px) {
    font-size: 20px;
    font-weight: 400;
  }
  }

.loginButtonFacebook {
  height: 50px;
  background-color: $color-facebook-blue;
  color: $color-white;
  font-size: 24px;
  border-radius: 8px;
  width: 100%;
  object-fit:contain;
  max-width: 365px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 12px;
  font-weight: 500;

  @media screen and (max-width: 430px) {
    font-size: 20px;
    font-weight: 400;
  }
}