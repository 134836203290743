@import '../../../styles/colors';
@import '../../../styles/font';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
}

.modal {
    background-color: white;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 500px;
    max-height: 600px;
    background-color: $color-white;
    border-radius: 5px;
    border: solid 1px $color-black;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;

    @media screen and (max-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
    }

    @media screen and (max-width: 420px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        max-height: 520px;
    }
}


.headerContainer {
    display: flex;
    justify-content: center;
}

.text {
    font-family: $font-v;
    font-size: 20px;
    line-height: 1.3;
    color: $color-grey-brown;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 26px;
    margin-top: 30px;

    @media screen and (max-width: 700px) {
        margin-left: 20px;
        margin-right: 20px;
    }

    @media screen and (max-width: 420px) {
        margin-left: 0px;
        margin-right: 0px;
    }

}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 40px;
    margin-right: 40px;

    @media screen and (max-width: 700px) {
        margin-left: 20px;
        margin-right: 20px;
    }

    @media screen and (max-width: 420px) {
        margin-left: 5px;
        margin-right: 5px;
    }

    @media screen and (max-width: 350px) {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.bottomButtons {
    margin-top: 20px;
    margin-bottom: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.cancel {
    text-decoration: underline;
    font-family: $font-gs;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    color: $color-grey-mid;
    position: relative;
    margin-top: 40px;
    cursor: pointer;

    @media screen and (max-width: 420px) {
        margin-top: 20px;
    }
}