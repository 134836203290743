@import '../../../styles/colors';
@import '../../styles/font';
@import '../../styles/margins';

.container {
    height: 100%;
    display: flex;
    flex-direction: row;
    max-width: 1024px;
    padding-bottom: $padding-bottom-large;
    height: 100%;

    @media screen and (min-width: 1025px) {
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column;
        padding-bottom: $padding-bottom-medium;
    }

    @media screen and (max-width: 363px) {
        padding-bottom: $padding-bottom-small;
    }

}

.leftColumn {
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    z-index: 10;

    @media screen and (max-width: 700px) {
        margin-left: 20px;
    }
}

.rightColumn {
    margin-left: -110px;
    
    @media screen and (max-width: 1023px) {
        margin-left: -120px;
    }
    
    @media screen and (max-width: 700px) {
        margin-left: 45px;
        margin-top: -30px;
    }
    
    @media screen and (max-width: 363px) {
        margin-left: 20px;
    }
}

.header {
    font-family: $font-gs;
    font-size: 36px;
    font-weight: 300;
    color: $color-grey-brown;
    margin-bottom: 42px;
    margin-top: 80px;

    @media screen and (max-width: 1024px) {
        font-size: 30px;
        margin-bottom: 34px;
    }
    
    @media screen and (max-width: 770px) {
        font-size: 26px;
        margin-top: 32px;
    }
   
    @media screen and (max-width: 720px) {
        font-size: 24px;
    }
}

.link {
    font-family: $font-gs;
    font-size: 24px;
    font-weight: 500;
    color: $color-blue-medium;
    text-decoration: underline;

    margin-left: 20px;
    margin-bottom: 36px;

    @media screen and (max-width: 1024px) {
        font-size: 20px;
        margin-bottom: 30px;
    }
    
    @media screen and (max-width: 700px) {
        font-size: 18px;
        margin-bottom: 27px;
    }
}

.image {
    width: 600px;
    height: 622px;
    margin-top: 75px;

    @media screen and (max-width: 1024px) {
        width: 450px;
        height: 466px;
        margin-top: 65px;
    }
    
    @media screen and (max-width: 700px) {
        width: 300px;
        height: 311px;
        margin-top: -20px;
    }
}