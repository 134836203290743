@import "../../styles/colors";
@import "../../styles/font";

.border {
  border-bottom: 1px solid $color-orange;
}
.container {
  display: flex;
  justify-content: space-between;
  height: 62px;
  max-width: 1024px;

  @media screen and (max-width: 600px) {
    height: 50px;
  }

  @media screen and (min-width: 1025px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.leftContainer {
  height: 100%;
  position: relative;
}

.basicLogo {
  position: absolute;
  margin-left: 2vw;
  bottom: 6px;
  width: 150px;

  @media screen and (max-width: 700px) {
    margin-left: 2vw;
    width: 107px;
  }
}

.rightContainer {
  display: flex;

  &:hover {
    opacity: 1;
    font-weight: 500;
  }
}

.statusButton {
  font-family: $font-gs;
  font-size: 20px;
  font-weight: 300;
  line-height: 3.1;
  text-align: right;
  color: $color-blue-medium;
  margin-right: 20px;
  background-color: $color-white;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    font-size: 17px;
    line-height: 3;
  }
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
