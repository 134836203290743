@import '../../../styles/colors';
@import '../../../styles/font';
@import '../../../styles/margins';

.ContainerPaused {
    opacity: .1;
}

.VideoStyle {
    position: absolute;
}

.Overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10;
    background: black;
    opacity: 0.6;
}

.Controls {
    z-index: 10;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.TopControls {
    max-height: 200px;
    max-width: 400px;
    position: absolute;

    z-index: 20;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: $font-gs;
    font-size: 18px;
    text-align: center;
    color: $color-grey-background;
}

.PlaybarButton {
    width: 50px;
    height: 50px;

    @media screen and (max-width: 835px) {
        width: 45px;
        height: 45px;
    }

    @media screen and (max-width: 700px) {
        width: 40px;
        height: 40px;
    }

    @media screen and (max-width: 520px) {
        width: 30px;
        height: 30px;
    }

    @media screen and (max-width: 345px) {
        width: 20px;
        height: 20px;
    }

}

.PlaybarButtonBigger {
    width: 70px;
    height: 70px;

    @media screen and (max-width: 835px) {
        width: 65px;
        height: 65px;
    }

    @media screen and (max-width: 700px) {
        width: 55px;
        height: 55px;
    }

    @media screen and (max-width: 520px) {
        width: 40px;
        height: 40px;
    }
   
    @media screen and (max-width: 345px) {
        width: 30px;
        height: 30px;
    }

}

.PlaybarButtonText {
    font-family: $font-v;
    font-size: 18px;
    text-align: center;
    color: $color-white;

    @media screen and (max-width: 700px) {
        font-size: 16px;
    }

    @media screen and (max-width: 520px) {
        font-size: 14px;
    }
}

.EachButton {
    display: flex;
    flex-direction: column;
    margin: 100px;
    align-items: center;

    @media screen and (max-width: 835px) {
        margin: 80px;
    }

    @media screen and (max-width: 700px) {
        margin: 70px;
    }
    
    @media screen and (max-width: 615px) {
        margin: 55px;
    }
 
    @media screen and (max-width: 520px) {
        margin: 45px;
    }

    @media screen and (max-width: 435px) {
        margin: 30px;
    }
   
    @media screen and (max-width: 345px) {
        margin: 20px;
    }

}